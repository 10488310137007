@import url(https://fonts.googleapis.com/css?family=Poiret+One|Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  background-color: white;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}

nav ul li {
  list-style: none;
  display: inline-block;
  padding: 10px 15px;
  color: black;
  margin-right: 10px;
}

nav li a {
  text-decoration: none;
  color: black;
}

body {
  background-image: url("https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: bottom 50px right 100px; */
}

.home__intro {
  /* padding-top: 40px; */
  margin-left: 80px;
  font-family:'Poiret One', cursive;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home__header {
  font-size:60px;
  padding-top: 20px;
}

.home__description {
  font-size:40px;
  width: 75%;
  color:#42464d;
}

.navbar__header{
  margin-left: 20px;
  text-decoration: none;
  color:black;
}

a {
  text-decoration: none;
}

.home__projects {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0
}

.home__projects li {
  /* text-decoration: none; */
  font-size: 1.5em;
  padding-top:20px;
  list-style: none;
  margin: 0;
  padding:0;
}

.home__portfolio ul li {
  text-decoration: none;
}

.home__portfolio {
  font-size: 2em;
  padding-top: 30px;
  font-weight: lighter;
}

.home__screenshot {
  width: 100%;
}

.contact__page {
  font-family:'Poiret One', cursive;
  font-weight: lighter;
}

.contact__content {
  margin-top: 50px;
  height: 50vh;
  display: flex;
  justify-content: center;
  font-size:20px;
}

.contact__name {
  height: 30px;
  width: 250px;
  margin-bottom: 20px;
  margin-right: 100px;
}

.contact__name::-webkit-input-placeholder {
  font-size:25px;
}

.contact__name:-ms-input-placeholder {
  font-size:25px;
}

.contact__name::-ms-input-placeholder {
  font-size:25px;
}

.contact__name::placeholder {
  font-size:25px;
}

.contact__email {
  height: 30px;
  width: 250px;
  margin-bottom: 20px;
}

.contact__email::-webkit-input-placeholder {
  font-size:25px;
}

.contact__email:-ms-input-placeholder {
  font-size:25px;
}

.contact__email::-ms-input-placeholder {
  font-size:25px;
}

.contact__email::placeholder {
  font-size:25px;
}

textarea {
  width: 610px;
  height: 120px;
  font-size: 20px;
}

.contact__button {
  border-style: solid;
  border-color: rgb(216, 216, 216);
  padding: 7px;
  font-size: 1em;
  font-family:'Poiret One', cursive;
}

input {
  padding-left: 5px;
  font-size:1em;
  font-family:'Poiret One', cursive;

}

.contact__textarea {
  margin-bottom:20px;
  font-family:'Poiret One', cursive;
}

.contact__header {
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight:bolder;
}

.about__image {
  width:100%;
  height: 209px;
  margin-right: 100px;

}

.about__images {
  margin-top: 60px;
}

.about__container {
  font-family:'Poiret One', cursive;
  font-weight: lighter;
  text-align: center;

}

.about__header {
  font-size: 32px;
  font-weight: lighter;
}

.about__intro {
  font-size: 24px;
  padding-top: 15px;
}

.about__images {
  display: flex;
  justify-content: space-between;
}

.about__logos {
  display: flex;
  justify-content: center; 
}

.linkedin img {
  height: 50px;
  margin-right: 50px;
}

.linkedin:hover {
  transform: scale(1.1);
}

.github img {
  height: 50px;
}
.github:hover {
  transform: scale(1.1);
}

.home__projects li {
  text-decoration: none;
  text-align: center;
  margin-bottom: 20px;
  padding:0;
}


.home__screenshots ul li {
  text-decoration: none;
}

.home__aboutme {
  padding-top: 30px;
  margin: 0;
  font-size:24px;
}

.home__portfoliolink {
  font-weight: bold;
  color:black;
  text-decoration: underline;
}
.profile__image {
  border-style: 1px solid white;
}

.about__images {
  display:flex;
  flex-wrap: wrap;
  /* flex-basis: 33%;
  flex-direction: row; */
}


.about__portfolio {
    list-style: none;
    width: 33%;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 10px;
}

.about__portfolio:last-child {
  margin-right: 0;
}

#copyright {
  color: black;
  background-color: white;
  text-align: center;
  padding: 20px 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; 
}
body {
    background-color: #F2F2F2;
}
